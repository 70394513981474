.thumbnail {
  z-index: var(--z-index-1);
}

/* Requires more specificity for production-specific bug */
button.playButton,
:global(.v4DesignSystem) button.playButton {
  position: absolute;
  z-index: var(--z-index-2);
  top: 50%;
  left: 50%;
  width: var(--button-size);
  height: var(--button-size);
  translate: -50% -50%;

  --button-size: 4rem;

  @media (--md) {
    --button-size: 8.8rem;

    figure {
      width: 60%;
      height: 60%;
    }
  }
}
